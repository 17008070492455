import React from "react";
import sixtyImg from "../../assets/images/sixtyImg.svg";

const SixthSection = () => {
  return (
    <div
      id="about"
      className="py-[6rem] 2xl:py-[10rem] px-[1rem] lg:px-[8rem] 2xl:px-[14rem]"
    >
      <div className="flex justify-between items-start flex-col lg:flex-row">
        <div className="30%">
          <h2 className="text-[#5271FF] text-[16px] 2xl:text-[24px] font-medium">
            About Vendyz
          </h2>
          <h2 className="text-[35px] 2xl:text-[50px] font-bold text-[#232323]">
            About us
          </h2>
        </div>
        <div className="w-full lg:w-[48%]">
          <h2 className="text-[16px] 2xl:text-[22px] text-[#5C5959] leading-7 2xl:leading-[44px]">
            Vendyz is a passionate team on a mission to revolutionize online
            transactions. We believe everyone deserves to buy and sell with
            confidence in the digital age.
          </h2>
          <h2 className="text-[16px] 2xl:text-[22px] text-[#5C5959] leading-7 2xl:leading-[44px] pt-6 2xl:pt-10">
            Born from the frustration of online payment insecurity, we saw a
            need for a platform that prioritizes both buyer protection and
            seller success. That's why we created Vendyz, a unique escrow-style
            payment platform that fosters trust and eliminates transaction
            anxieties.
          </h2>
        </div>
      </div>
      <div className="2xl:w-full 2xl:h-full pt-[3rem] 2xl:pt-[5.5rem]">
        <img src={sixtyImg} alt="img" className="w-full h-full" />
      </div>
      <div className="flex justify-end items-end flex-col pt-[3rem] 2xl:pt-[5.5rem]">
        <div className="w-full lg:w-[47%] 2xl:w-[44%]">
          <h2 className="text-[#232323] text-[23px] 2xl:text-[30px] font-bold">
            Here's what sets us apart:
          </h2>
          <div className="pt-[1.4rem] 2xl:pt-[12px]">
            <AboutContent
              name={"Unwavering Commitment to Security"}
              content={
                "We utilize industry-leading security measures and are fully compliant to safeguard your financial information."
              }
            />
            <AboutContent
              name={"Focus on User Experience"}
              content={
                "Vendyz is designed for everyone. Our platform is user-friendly and intuitive, making online payments effortless for both buyers and sellers."
              }
            />
            <AboutContent
              name={"Empowering Innovation"}
              content={
                "We support the growth of the online economy by providing businesses, from established e-commerce stores to social commerce entrepreneurs, with secure and efficient payment solutions."
              }
            />
            <AboutContent
              name={"Building a Community of Trust"}
              content={
                "At Vendyz, we believe trust is the cornerstone of successful online interactions. Our platform fosters communication and provides dispute-resolution tools to ensure a smooth experience for all parties involved."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixthSection;

const AboutContent = ({ name, content }) => {
  return (
    <div className="py-3">
      <h2 className="text-[#232323] font-medium text-[17px] 2xl:text-[23px] pb-1 2xl:p-2">
        {name}
      </h2>
      <h2 className="text-[16px] 2xl:text-[22px] text-[#5C5959]">{content}</h2>
    </div>
  );
};
