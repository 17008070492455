import React from "react";
import NavBar from "../../components/NavBar";
import HeroSection from "../../components/HeroSection";
import SecondSection from "../../components/SecondSection";
import ThirdSection from "../../components/ThirdSection";
import FourthSection from "../../components/FourthSection";
import FifthSection from "../../components/FifthSection";
import SixthSection from "../../components/SixthSection";
import SeventhSection from "../../components/SeventhSection";
import ContactUs from "../../components/ContactUs";
import NinethSection from "../../components/NinethSection";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <div>
      <NavBar />
      <HeroSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <ContactUs />
      <NinethSection />
      <Footer />
    </div>
  );
};

export default Home;
