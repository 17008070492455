import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import slide1 from "../../assets/images/content-active-state.svg";
import slide2 from "../../assets/images/content.svg";
import slide3 from "../../assets/images/content3.svg";
import slide4 from "../../assets/images/content4.svg";
import slide5 from "../../assets/images/content5.svg";
import slide6 from "../../assets/images/content6.svg";
import slide7 from "../../assets/images/content7.svg";
import slide8 from "../../assets/images/content8.svg";
import vendorSlide1 from "../../assets/images/vendorContent1.svg";
import vendorSlide2 from "../../assets/images/vendorContent2.svg";
import vendorSlide3 from "../../assets/images/vendorContent3.svg";
import vendorSlide4 from "../../assets/images/vendorContent4.svg";
import vendorSlide5 from "../../assets/images/vendorContent5.svg";
import vendorSlide6 from "../../assets/images/vendorContent6.svg";
import { SwiperNavButtons } from "../SwiperNavButtons";

const ThirdSection = () => {
  const [buyer, setBuyer] = useState(true);
  const [vendor, setVendor] = useState(false);

  const handleClickVendor = () => {
    setBuyer(false);
    setVendor(true);
  };
  const handleClickBuyer = () => {
    setBuyer(true);
    setVendor(false);
  };

  return (
    <div
      id="how-it-works"
      className="px-[0.5rem] lg:px-[8rem] 2xl:px-[14rem] pt-[4.5rem] lg:pt-[6rem] 2xl:pt-[9rem] pb-[8rem] 2xl:pb-[11rem]"
    >
      <h2 className="text-[#5271FF] text-[16px] 2xl:text-[22px] text-center">
        Simple Steps to Effortless Payments
      </h2>
      <h2 className="text-[28px] 2xl:text-[32px] font-semibold text-center 2xl:pt-3">
        How it works
      </h2>
      <h2 className="text-[#5C5959] text-[17px] 2xl:text-[21px] text-center pt-1 2xl:pt-3">
        Vendyz takes the complexity out of online payments, making it easy{" "}
        <br /> for you and your customers. Here's a breakdown of the process:
      </h2>
      <div className="text-center pt-4 2xl:pt-5 pb-[4rem]">
        <button
          onClick={handleClickBuyer}
          className={`p-2 text-[16px] 2xl:text-[20px]  2xl:py-4 2xl:px-8 ${
            buyer ? "bg-[#5271FF] text-white" : "bg-[#F2F3F4] text-[#5C5959]"
          }`}
        >
          For Buyers
        </button>
        <button
          onClick={handleClickVendor}
          className={`p-2 text-[16px] 2xl:text-[20px] 2xl:py-4 2xl:px-8 border border-[#F2F3F4]  ${
            vendor ? "bg-[#5271FF] text-white" : "bg-[#F2F3F4] text-[#5C5959]"
          }`}
        >
          For Vendors
        </button>
      </div>
      <div className="">
        {buyer && (
          <Swiper
            spaceBetween={9}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1.66,
              },
              0: {
                slidesPerView: 1,
              },
            }}
          >
            <SwiperSlide>
              <img src={slide1} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide2} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide3} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide4} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide5} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide6} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide7} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={slide8} alt="" className="2xl:w-full 2xl:h-full" />
            </SwiperSlide>
            <SwiperNavButtons />
          </Swiper>
        )}
        {vendor && (
          <Swiper
            spaceBetween={9}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1.66,
              },
              0: {
                slidesPerView: 1,
              },
            }}
          >
            <SwiperSlide>
              <img
                src={vendorSlide1}
                alt=""
                className="2xl:w-full 2xl:h-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={vendorSlide2}
                alt=""
                className="2xl:w-full 2xl:h-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={vendorSlide3}
                alt=""
                className="2xl:w-full 2xl:h-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={vendorSlide4}
                alt=""
                className="2xl:w-full 2xl:h-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={vendorSlide5}
                alt=""
                className="2xl:w-full 2xl:h-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={vendorSlide6}
                alt=""
                className="2xl:w-full 2xl:h-full"
              />
            </SwiperSlide>
            <SwiperNavButtons />
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default ThirdSection;
