import Home from "./pages/Home";

function App() {
  return (
    <div className="!font-plusJakartaSans tracking-[-0.5px]">
      <Home />
    </div>
  );
}

export default App;
