import React from "react";
import { useSwiper } from "swiper/react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

export const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="flex justify-start items-center gap-5 ml-4">
      <button
        className="border 2xl:border-[1.5px] border-[#E7E2E3] active:border active:border-[#5271FF] px-6 py-3 2xl:px-9 2xl:py-4 text-[#9B9697] cursor-pointer"
        onClick={() => swiper.slidePrev()}
      >
        <FaArrowLeftLong className="2xl:text-[1.7rem]" />
      </button>
      <button
        className="border 2xl:border-[1.5px] border-[#E7E2E3] active:border active:border-[#5271FF] px-6 py-3 2xl:px-9 2xl:py-4 text-[#9B9697] cursor-pointer"
        onClick={() => swiper.slideNext()}
      >
        <FaArrowRightLong className="2xl:text-[1.7rem]" />
      </button>
    </div>
  );
};
