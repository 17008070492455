import React from "react";
import contactImg from "../../assets/images/contactImg.svg";
import minusCircleIcon from "../../assets/icons/minusCirlceIcon.svg";

const SeventhSection = () => {
  return (
    <div
      id="faqs"
      className="py-[7rem] 2xl:py-[10rem] px-[1rem] lg:px-[8rem] 2xl:px-[14rem] bg-[#FAF2FA]"
    >
      <div className="overflow-x-auto whitespace-nowrap">
        <button className="inline-block min-w-[200px] py-3 2xl:py-4 text-[#FFFFFF] text-[15px] 2xl:text-[22px] bg-[#5271FF]">
          General questions
        </button>
        <button className="inline-block min-w-[200px] py-3 2xl:py-4 text-[#5C5959] text-[15px] 2xl:text-[22px] bg-[#F2F3F4]">
          Vendor Onboarding
        </button>
        <button className="inline-block min-w-[200px] py-3 2xl:py-4 text-[#5C5959] text-[15px] 2xl:text-[22px] bg-[#F2F3F4]">
          Payments and Orders
        </button>
        <button className="inline-block min-w-[200px] py-3 2xl:py-4 text-[#5C5959] text-[15px] 2xl:text-[22px] bg-[#F2F3F4]">
          Customer Payments
        </button>
        <button className="inline-block min-w-[200px] py-3 2xl:py-4 text-[#5C5959] text-[15px] 2xl:text-[22px] bg-[#F2F3F4]">
          Support and Security
        </button>
      </div>
      <div className="flex justify-between items-start flex-col gap-10 lg:gap-0 lg:flex-row pt-[3rem] 2xl:pt-[5rem]">
        <div className="w-full lg:w-[40%]">
          <h2 className="font-bold text-[30px] 2xl:text-[43px] leading-9 2xl:leading-[50px]">
            Frequently asked <br /> questions
          </h2>
          <h2 className="text-[#5C5959] text-[16px] 2xl:text-[22px] pt-5 pb-[3rem]">
            What you need to know about Vendyz.
          </h2>
          <div className="bg-[#DCE1F5] flex justify-start items-center flex-col w-full lg:w-[83%] 2xl:w-[89%] text-center px-5 py-6">
            <div className="w-full lg:w-[70%]">
              <img src={contactImg} alt="img" className="w-full h-full" />
            </div>
            <h2 className="font-bold text-[16px] 2xl:text-[22px] pt-6">
              Still have questions?
            </h2>
            <h2 className="text-[#5C5959] 2xl:text-[20px] pt-3 2xl:pt-4 pb-6 2xl:pb-7">
              Can’t find the answer you are looking for? Please contact us to
              serve you better.
            </h2>
            <button className="py-2 px-2 bg-[#5271FF] text-white 2xl:text-[20px] 2xl:w-[40%]">
              Contact us
            </button>
          </div>
        </div>
        <div className="w-full lg:w-[47%] 2xl:w-[48%]">
          <FAQ
            name={"What is Vendyz"}
            content={
              "Vendyz is a secure and user-friendly online payment platform that simplifies transactions for vendors and customers through an escrow-style system that protects both buyers and sellers."
            }
          />
          <FAQ
            name={"Who can use Vendyz?"}
            content={
              "Essentially, Vendyz is perfect for businesses of all sizes. Anyone who wants to buy or sell online with added security and peace of mind can benefit from using Vendyz. The platform empowers both parties."
            }
            border={true}
          />
          <FAQ
            name={"Is Vendyz secure?"}
            content={
              "Absolutely! Vendyz prioritizes security with features like multi-factor authentication and industry-leading security measures to protect your information and final transactions."
            }
            border={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SeventhSection;

const FAQ = ({ name, content, border }) => {
  return (
    <div
      className={`${
        border
          ? "border-t border-[#E7E2E3] py-[27px] 2xl:py-[30px]"
          : "pb-[27px] 2xl:pb-[30px]"
      }`}
    >
      <div className="flex justify-between items-center">
        <h2 className="text-[17px] 2xl:text-[23px] font-medium text-[#232323]">
          {name}
        </h2>
        <div className="2xl:w-[4%]">
          <img src={minusCircleIcon} alt="icon" className="w-full h-full" />
        </div>
      </div>
      <h2 className="text-[16px] 2xl:text-[20px] text-[#5C5959] pt-2 w-[88%] 2xl:w-[90%] leading-7 2xl:leading-10">
        {content}
      </h2>
    </div>
  );
};
