import React from "react";
import heroImg from "../../assets/images/Magnifier.svg";
import { NavHashLink } from "react-router-hash-link";

const HeroSection = () => {
  return (
    <div
      id="home"
      className="flex flex-col lg:flex-row justify-between items-start gap-8 lg:gap-0 pl-[1rem] lg:pl-[8rem] 2xl:pl-[14rem] pt-[3rem] lg:pt-[6rem] 2xl:pt-[7rem] pb-[4rem] 2xl:pb-[6rem]"
    >
      <div className="w-full lg:w-[38%] 2xl:w-[35%] flex justify-start items-start gap-2 2xl:gap-6 flex-col">
        <h2 className="text-[#5271FF] text-[16px] 2xl:text-[22px]">
          Secure. Fast. Transaction.
        </h2>
        <h1 className="text-[33px] lg:text-[40px] w-[90%] 2xl:text-[50px] 2xl:w-[90%] font-bold">
          Your trusted security for all business payments
        </h1>
        <h2 className="text-[#5C5959] text-[17px] 2xl:text-[21px] w-[90%]">
          We ensure your complete satisfaction before money or goods are
          released to the other party.
        </h2>
        <div className="flex justify-start items-center gap-7 pt-[1.7rem] 2xl:pt-[1.4rem]">
          <NavHashLink to="/#join-wait-list" smooth>
            <button className="py-1 px-3 2xl:py-2 2xl:px-4 text-[16px] 2xl:text-[20px] bg-[#5271FF] text-[#FBFBFB]  border border-[#5271FF]">
              Join Wait List
            </button>
          </NavHashLink>
          <NavHashLink to="/#how-it-works" smooth>
            <button className="py-1 px-3 2xl:py-2 2xl:px-4 text-[16px] 2xl:text-[20px] bg-[#FBFBFB] border border-[#5271FF] text-[#5271FF]">
              How it works
            </button>
          </NavHashLink>
        </div>
      </div>

      <div className="w-full lg:w-[50%] 2xl:w-[52%]">
        <img src={heroImg} alt="img" className="w-full h-full" />
      </div>
    </div>
  );
};

export default HeroSection;
