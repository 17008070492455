import React from "react";
import fifthImg from "../../assets/images/fifthImg.svg";

const FifthSection = () => {
  return (
    <div className="bg-[#5271FF] flex justify-between items-center flex-col lg:flex-row w-full pt-[5rem] lg:pt-[8rem] 2xl:pt-[12rem] pl-[1rem] lg:pl-[8rem] 2xl:pl-[14rem] gap-10 lg:gap-0">
      <div className="lg:w-[30%] 2xl:w-[32%]">
        <h2 className="text-[35px] 2xl:text-[50px] font-bold text-white leading-tight">
          Join the Vendyz community today!
        </h2>
        <h2 className="text-[#ffffff] text-[18px] 2xl:text-[22px] pt-5 2xl:pt-6 pb-10 lg:pb-14 2xl:pb-16">
          Let’s work together to build a brighter <br /> future for online
          payments.
        </h2>
        <button className="text-[16px] 2xl:text-[20px] bg-[#FFFFFF] text-[#5271FF] py-1.5 px-2 w-[55%] 2xl:w-[50%] 2xl:py-2 2xl:px-3 font-medium">
          Join our community
        </button>
      </div>
      <div className="lg:w-[53%] 2xl:w-[54%]">
        <img src={fifthImg} alt="img" className="w-full h-full" />
      </div>
    </div>
  );
};

export default FifthSection;
