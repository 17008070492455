import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import { NavHashLink } from "react-router-hash-link";
import menuBarIcon from "../../assets/icons/navIcon.svg";
import { IoClose } from "react-icons/io5";

const NavBar = () => {
  const [mobileNav, setMobileNav] = useState(false);

  const handleShowNav = () => {
    setMobileNav(!mobileNav);
  };

  return (
    <>
      <nav className="hidden lg:flex justify-between items-center px-[8rem] 2xl:px-[15rem] py-[1rem] 2xl:py-[2rem]">
        <div className="w-[4.5%] 2xl:w-[5%]">
          <img src={logo} alt="logo" className="w-full h-full" />
        </div>
        <div className="w-[55%] 2xl:w-[50%]">
          <ul className="flex justify-between items-center w-full text-[16px] 2xl:text-[20px] font-medium text-[#5C5959]">
            <NavHashLink to="/" smooth>
              <li className="cursor-pointer">Home</li>
            </NavHashLink>
            <NavHashLink to="/#about" smooth>
              <li className="cursor-pointer">About</li>
            </NavHashLink>
            <NavHashLink to="/#career" smooth>
              <li className="cursor-pointer">Career</li>
            </NavHashLink>
            <NavHashLink to="/#how-it-works" smooth>
              <li className="cursor-pointer">How it works</li>
            </NavHashLink>
            <NavHashLink to="/#faqs" smooth>
              <li className="cursor-pointer">FAQs</li>
            </NavHashLink>
            <NavHashLink to="/#contact-us" smooth>
              <li className="cursor-pointer">Contact us</li>
            </NavHashLink>
          </ul>
        </div>
        <NavHashLink to="/#join-wait-list" smooth>
          <button className="text-[16px] 2xl:text-[20px] bg-black text-white py-1 px-2 2xl:py-2 2xl:px-3 font-medium">
            Join Wait List
          </button>
        </NavHashLink>
      </nav>

      <nav className="flex justify-between items-center lg:hidden px-5 py-3">
        <div className="flex justify-start items-center gap-8">
          <div onClick={handleShowNav} className="">
            <img src={menuBarIcon} alt="icon" />
          </div>
          <div className="w-[30%]">
            <img src={logo} alt="logo" className="w-full h-full" />
          </div>
        </div>

        <NavHashLink to="/#join-wait-list" smooth>
          <button className="text-[16px] 2xl:text-[20px] bg-black text-white py-1 px-2 2xl:py-2 2xl:px-3 font-medium">
            Join Wait List
          </button>
        </NavHashLink>
      </nav>

      <nav
        className={`fixed inset-0 z-30 flex ${
          mobileNav ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-500 ease-in-out`}
      >
        <div className="bg-white w-3/5 h-full shadow-lg p-4">
          <div className="flex justify-end">
            <button onClick={handleShowNav}>
              <IoClose className="text-[1.4rem]" />
            </button>
          </div>
          <nav>
            <ul className="flex flex-col gap-4 mt-4">
              <NavHashLink to="/" smooth onClick={handleShowNav}>
                <li className="text-[18px] text-[#5C5959] cursor-pointer">
                  Home
                </li>
              </NavHashLink>
              <NavHashLink to="/#about" smooth onClick={handleShowNav}>
                <li className="text-[18px] text-[#5C5959] cursor-pointer">
                  About
                </li>
              </NavHashLink>
              <NavHashLink to="/#career" smooth onClick={handleShowNav}>
                <li className="text-[18px] text-[#5C5959] cursor-pointer">
                  Career
                </li>
              </NavHashLink>
              <NavHashLink to="/#how-it-works" smooth onClick={handleShowNav}>
                <li className="text-[18px] text-[#5C5959] cursor-pointer">
                  How it works
                </li>
              </NavHashLink>
              <NavHashLink to="/#faqs" smooth onClick={handleShowNav}>
                <li className="text-[18px] text-[#5C5959] cursor-pointer">
                  FAQs
                </li>
              </NavHashLink>
              <NavHashLink to="/#contact-us" smooth onClick={handleShowNav}>
                <li className="text-[18px] text-[#5C5959] cursor-pointer">
                  Contact us
                </li>
              </NavHashLink>
              <NavHashLink to="/#join-wait-list" smooth onClick={handleShowNav}>
                <button className="text-[16px] 2xl:text-[20px] bg-black text-white py-1 px-2 2xl:py-2 2xl:px-3 font-medium">
                  Join Wait List
                </button>
              </NavHashLink>
            </ul>
          </nav>
        </div>
        <div
          className="w-2/5 h-full bg-black opacity-50"
          onClick={handleShowNav}
        ></div>
      </nav>
    </>
  );
};

export default NavBar;
