import React from "react";

const NinethSection = () => {
  return (
    <div className="py-[6rem] 2xl:py-[10rem] px-[1rem] lg:px-[8rem] 2xl:px-[14rem] bg-[#FBFBFB] flex justify-center items-center">
      <div className="text-center w-full lg:w-[42%] 2xl:w-[45%]">
        <h2 className="text-[25px] lg:text-[30px] 2xl:text-[38px] font-bold leading-[35px] lg:leading-[45px] 2xl:leading-[50px] pb-[2rem] lg:pb-[3rem] 2xl:pb-[4rem] tracking-wide">
          Vendyz is
          <span className="text-[#5271FF]">
            {" "}
            more than just <br className="hidden lg:flex" /> a payment gateway.
          </span>
          It's <br /> your trusted partner in <br className="hidden lg:flex" />{" "}
          <span className="text-[#5271FF]">
            streamlining your online <br className="hidden lg:flex" /> finances
          </span>
        </h2>

        <button className="bg-[#5271FF] text-white 2xl:text-[22px] py-3 2xl:py-4 px-3 2xl:w-[60%] tracking-wide">
          Streamline your finances
        </button>
      </div>
    </div>
  );
};

export default NinethSection;
