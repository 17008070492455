import React, { useEffect, useState } from "react";
import sectionTwo from "../../assets/images/Image2.svg";
import sendIcon from "../../assets/icons/icon.svg";
import vendorIcon from "../../assets/icons/vendorIcon.svg";
import tickIcon from "../../assets/icons/tickIcon.svg";
import buyerBlueIcon from "../../assets/icons/buyerBlueIcon.svg";
import vendorBlueIcon from "../../assets/icons/vendorBlueIcon.svg";
import { NavHashLink } from "react-router-hash-link";

const SecondSection = () => {
  const [buyer, setBuyer] = useState();
  const [vendor, setVendor] = useState();
  const [validated, setValidated] = useState(false);

  const handleBuyerClick = () => {
    setBuyer(true);
    setVendor(false);
  };
  const handleVendorClick = () => {
    setVendor(true);
    setBuyer(false);
  };
  useEffect(() => {
    if (buyer || vendor) {
      setValidated(true);
    }
  }, [buyer, vendor]);

  return (
    <div
      id="join-wait-list"
      className="px-[1rem] lg:px-[8rem] 2xl:px-[14rem] py-[2rem] lg:py-[6rem] 2xl:py-[12rem] bg-[#F8F8F8] flex flex-col lg:flex-row justify-between items-start gap-10 lg:gap-0"
    >
      <div className="lg:w-[35%] 2xl:w-[35%] flex justify-between items-center flex-col gap-5">
        <h2 className="text-[32px] 2xl:text-[40px] font-bold pb-[1rem] 2xl:pb-[2.3rem] ">
          Let’s help you start your transaction
        </h2>
        <div className="2xl:w-[100%]">
          <img src={sectionTwo} alt="img" className="w-full h-full" />
        </div>
      </div>

      <div className="w-full lg:w-[58%] 2xl:w-[58%] flex justify-start items-center flex-col">
        <h2 className="text-center text-[24px] lg:text-[20px] 2xl:text-[30px] font-bold px-[2rem]">
          Choose an account type that suits you
        </h2>
        <h3 className="text-[16px] 2xl:text-[24px] text-[#5C5959] text-center">
          This will enable us to serve you better.
        </h3>
        <div className="flex justify-between items-center flex-col lg:flex-row pt-[30px] 2xl:pt-[40px] 2xl:w-[95%] gap-8 lg:gap-0">
          <div
            onClick={handleBuyerClick}
            className={`w-[75%] lg:w-[41%] 2xl:w-[300px] 2xl:h-[260px] cursor-pointer relative rounded-3xl p-[20px] 2xl:p-[25px]  flex justify-between items-start flex-col ${
              buyer
                ? "border border-[#5271FF] bg-[#F3F5FD]"
                : " border border-[#E7E2E3] bg-[#FBFBFB]"
            }`}
          >
            {buyer ? (
              <img src={buyerBlueIcon} alt="icon" />
            ) : (
              <img src={sendIcon} alt="icon" />
            )}
            <div className="">
              <h2 className="text-[17px] 2xl:text-[22px] font-bold pt-[2rem]">
                Buyer
              </h2>
              <h2 className="text-[#5C5959] text-[16px] 2xl:text-[20px] 2xl:pt-3">
                I’m buying or paying for goods or services.
              </h2>
            </div>

            {buyer && (
              <div className="absolute -top-2.5 -right-2.5 2xl:-top-3 2xl:-right-4 z-20 2xl:w-[12%]">
                <img src={tickIcon} alt="icon" className=" w-full h-full" />
              </div>
            )}
          </div>

          <div
            onClick={handleVendorClick}
            className={`w-[75%] lg:w-[41%]  2xl:w-[300px] 2xl:h-[260px] cursor-pointer rounded-3xl p-[15px] relative 2xl:p-[25px] bg-[#FBFBFB] flex justify-between items-start flex-col ${
              vendor
                ? "border border-[#5271FF] bg-[#F3F5FD]"
                : "border border-[#E7E2E3] bg-[#FBFBFB]"
            }`}
          >
            {vendor ? (
              <img src={vendorBlueIcon} alt="icon" />
            ) : (
              <img src={vendorIcon} alt="icon" />
            )}
            <div className="">
              <h2
                className={`text-[17px] 2xl:text-[22px] font-bold pt-[2rem] `}
              >
                Vendor/Seller
              </h2>
              <h2 className="text-[#5C5959] text-[16px] 2xl:text-[20px] 2xl:pt-3">
                I’m creating and sending payment links.
              </h2>
            </div>

            {vendor && (
              <div className="absolute -top-2.5 -right-2.5 2xl:-top-3 2xl:-right-4 z-20 2xl:w-[12%]">
                <img src={tickIcon} alt="icon" className=" w-full h-full" />
              </div>
            )}
          </div>
        </div>
        <div className="w-full 2xl:w-[95%] pt-[5rem] lg:pt-[8.8rem] 2xl:pt-[11rem]">
          <NavHashLink
            to="https://app.vendyz.com/waitlist"
            target="_blank"
            smooth
          >
            <button
              disabled={validated === false}
              className={`w-full  py-2 2xl:py-4 text-white text-[16px] 2xl:text-[20px] ${
                validated
                  ? "bg-[#5271FF] border border-[#5271FF]"
                  : "bg-[#C3CEFF] border border-[#C3CEFF] cursor-not-allowed"
              }`}
            >
              Continue
            </button>
          </NavHashLink>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
