import React from "react";
import footerImg from "../../assets/images/textImg.svg";
import footerImg2 from "../../assets/images/footerImg.svg";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div className="pt-[4rem] 2xl:pt-[7rem] pb-[1.5rem] 2xl:pb-[3rem] px-[1rem] lg:px-[8rem] 2xl:px-[14rem] bg-[#EAEAEA]">
      <div className="flex justify-center items-center">
        <div className="w-[80%] lg:w-[60%]">
          <img src={footerImg} alt="img" className="w-full h-full" />
        </div>
      </div>
      <div className="flex justify-between items-start lg:items-center flex-col lg:flex-row gap-10 lg:gap-0 pt-[2.5rem] pb-[3rem] border-b border-[#D6D6D6]">
        <div className="w-[22%]">
          <img src={footerImg2} alt="img" className="w-full h-full" />
        </div>
        <div className="flex justify-between items-start flex-col lg:flex-row gap-10 lg:gap-0 lg:w-[50%] ">
          <div className="">
            <ul className="flex justify-center items-start lg:items-end gap-4 2xl:gap-8 text-[17px] 2xl:text-[20px] font-semibold flex-col text-[#5C5959]">
              <NavHashLink to="/#home" smooth>
                <li className="cursor-pointer">Home</li>
              </NavHashLink>
              <NavHashLink to="/#about" smooth>
                <li className="cursor-pointer">About</li>
              </NavHashLink>
              <NavHashLink to="/#career" smooth>
                <li className="cursor-pointer">Career</li>
              </NavHashLink>
              <NavHashLink to="/#how-it-works" smooth>
                <li className="cursor-pointer">How it works</li>
              </NavHashLink>
              <NavHashLink to="/#faqs" smooth>
                <li className="cursor-pointer">FAQs</li>
              </NavHashLink>
              <NavHashLink to="/#contact-us" smooth>
                <li className="cursor-pointer">Contact us</li>
              </NavHashLink>
            </ul>
          </div>
          <div className="">
            <ul className="flex justify-start items-start lg:items-end gap-4 2xl:gap-8 text-[17px] 2xl:text-[20px] font-semibold flex-col text-[#5C5959]">
              <li className="cursor-pointer">Terms</li>
              <li className="cursor-pointer">Privacy</li>
              <li className="cursor-pointer">Blog</li>
            </ul>
          </div>
          <div className="">
            <ul className="flex justify-center items-start gap-4 2xl:gap-8 text-[17px] 2xl:text-[20px] font-semibold flex-col text-[#5C5959]">
              <NavHashLink to="/#join-wait-list" smooth>
                <li className="cursor-pointer">Use Vendyz</li>
              </NavHashLink>
              <NavHashLink to="/#join-wait-list" smooth>
                <li className="bg-[#5271FF] py-2 px-3 text-white cursor-pointer">
                  Join Wait List
                </li>
              </NavHashLink>
            </ul>
          </div>
        </div>
      </div>
      <h2 className="text-[#5C5959] pt-[1.5rem] 2xl:text-[20px]">
        © 2023 Vendyz. All rights reserved.
      </h2>
    </div>
  );
};

export default Footer;
