import React from "react";
import vendzyImg from "../../assets/images/fourthbgimg.svg";
import unmatchIcon from "../../assets/icons/unmatchIcon.svg";
import effortPaymentIcon from "../../assets/icons/effortPaymentIcon.svg";
import buyerProtection from "../../assets/icons/buyerProtectionIcon.svg";
import sellerPayment from "../../assets/icons/sellerPaymentIcon.svg";
import disputeResolution from "../../assets/icons/disputeResolutionIcon.svg";

const ForthSecton = () => {
  return (
    <div className="px-[1rem] lg:px-[8rem] 2xl:px-[14rem] py-[3rem] lg:py-[7rem] 2xl:py-[12rem] bg-[#F2F3F4] relative">
      <div className="text-center relative z-10">
        <div className="">
          <h2 className="text-[16px] 2xl:text-[22px] text-[#5271FF] font-semibold">
            Features
          </h2>
          <h2 className="text-[28px] 2xl:text-[34px] font-bold">
            Why choose us
          </h2>
          <h2 className="text-[17px] 2xl:text-[23px] pt-1 text-[#5C5959]">
            We provide innovative escrow-style payment protection for both{" "}
            <br />
            buyers and sellers, ensuring a smooth and secure experience for all.
          </h2>
        </div>
      </div>
      <div className="absolute top-0 right-0 z-0 w-[50%] 2xl:w-[52%]">
        <img src={vendzyImg} alt="img" className="w-full h-full" />
      </div>
      <div className="pt-[4rem] flex justify-center items-center flex-col lg:flex-row flex-wrap gap-7 2xl:gap-11">
        <Content
          icon={unmatchIcon}
          name={"Unmatched Security"}
          content={
            "Industry-leading security measures protect your financial information."
          }
        />
        <Content
          icon={effortPaymentIcon}
          name={"Effortless Payments"}
          border={"true"}
          content={
            "Generate unique payment links in seconds. Accept all major payment methods."
          }
        />
        <Content
          icon={buyerProtection}
          name={"Buyer Protection"}
          content={
            "Your money is securely held in escrow until you're satisfied with your purchase."
          }
        />
        <Content
          icon={sellerPayment}
          border={"true"}
          name={"Seller Payments"}
          content={
            "Get paid upon order fulfillment, eliminating non-payment risk."
          }
        />
        <Content
          icon={disputeResolution}
          name={"Dispute Resolution"}
          content={
            "Tools for communication and issue resolution are built-in for efficiency."
          }
          border={"true"}
        />
      </div>
    </div>
  );
};

export default ForthSecton;

const Content = ({ icon, name, content }) => {
  return (
    <div
      className={`bg-[#FBFBFB] w-[80%] lg:w-[30%] 2xl:w-[30%] p-4 2xl:p-6 flex justify-start items-center flex-col gap-2 2xl:gap-3 h-[35vh] 2xl:h-[29vh] ${
        name === "Effortless Payments"
          ? "border-r border-b border-[#5271FF] "
          : ""
      } ${
        name === "Seller Payments" ? "border-l border-b border-[#5271FF] " : ""
      } ${name === "Dispute Resolution" ? "border-r border-[#5271FF] " : ""}`}
    >
      <div className="2xl:w-[20%]">
        <img src={icon} alt="img" className="w-full h-full" />
      </div>
      <h2 className="text-[#232323] text-[17px] 2xl:text-[22px] font-bold">
        {name}
      </h2>
      <h2 className="text-center text-[#5C5959] w-[90%] text-[16px] 2xl:text-[20px]">
        {content}
      </h2>
    </div>
  );
};
