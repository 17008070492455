import React from "react";
import contactImg from "../../assets/images/contactUsImg.svg";

const ContactUs = () => {
  return (
    <div
      id="contact-us"
      className="py-[6rem] 2xl:py-[10rem] px-[1rem] lg:px-[8rem] 2xl:px-[14rem] bg-[#F2F3F4] flex justify-between items-start flex-col-reverse lg:flex-row "
    >
      <div className="w-full lg:w-[40%]">
        <div className="pt-5">
          <label className="text-[16px] 2xl:text-[22px] text-[#232323] pb-2">
            Full name
          </label>
          <br />
          <input
            type="text"
            className="outline-none border  border-[#E7E2E3] p-2  w-full mt-1 bg-[#FBFBFB] 2xl:text-[1.5rem]"
          />
        </div>
        <div className="pt-4">
          <label className="text-[16px] 2xl:text-[22px] text-[#232323] pb-2">
            Email
          </label>{" "}
          <br />
          <input
            type="email"
            className="outline-none border  border-[#E7E2E3] p-2 w-full mt-1 bg-[#FBFBFB] 2xl:text-[1.5rem]"
          />
        </div>
        <div className="pt-4 pb-[3rem]">
          <label className="text-[16px] 2xl:text-[22px] text-[#232323] pb-2">
            Your message
          </label>{" "}
          <br />
          <textarea className="outline-none border border-[#E7E2E3] p-2 resize-none 2xl:text-[1.5rem] w-full h-[16vh] mt-1 bg-[#FBFBFB]" />
        </div>

        <button className="bg-[#C3CEFF] text-white font-medium py-2 px-3 2xl:py-3 2xl:px-4 2xl:text-[22px]">
          Send message
        </button>
      </div>

      <div className="w-full lg:w-[50%] flex justify-center items-center  flex-col gap-8 lg:gap-4 2xl:gap-[2rem] 2xl:pt-4">
        <div className="w-full flex justify-center items-center lg:items-start flex-col lg:pl-[5rem] 2xl:pl-[6rem]">
          <h2 className="text-[#232323] font-bold text-[35px] 2xl:text-[40px] pb-1 2xl:pb-3">
            Contact us
          </h2>
          <h2 className="text-[#5C5959] text-[18px] 2xl:text-[24px]">
            Send us a message and our dedicated <br /> team will respond to you
            within 24 hours.
          </h2>
        </div>

        <div className="w-[80%] lg:w-[86%] 2xl:w-[85%]">
          <img src={contactImg} alt="img" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
